import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import { useWallet } from "@txnlab/use-wallet-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountSelection',
  setup(__props) {

const { wallets, activeWallet, activeAccount } = useWallet();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(activeAccount)?.address)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Select your account at any of the following AVM wallets", -1)),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(wallets), (wallet) => {
              return (_openBlock(), _createElementBlock("div", {
                key: wallet.id
              }, [
                _createElementVNode("button", {
                  class: "btn btn-primary my-2",
                  onClick: ($event: any) => (wallet.connect())
                }, _toDisplayString(wallet.metadata.name), 9, _hoisted_2)
              ]))
            }), 128))
          ]),
          (_unref(activeAccount)?.address)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Active Account", -1)),
                _createElementVNode("p", null, _toDisplayString(_unref(activeAccount)?.address), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(activeWallet))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Active Wallet", -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(activeWallet).metadata.name), 1),
          _createElementVNode("button", {
            class: "btn btn-primary my-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(activeWallet).disconnect()))
          }, " Disconnect ")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})